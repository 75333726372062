import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Main = styled.div`
  padding: 0 6px;
  border-radius: 4px;

  &.done {
    color: ${colorsV2.green100};
    background-color: ${colorsV2.green10};
  }
  &.in-progress {
    color: ${colorsV2.blue100};
    background-color: ${colorsV2.blue10};
  }
  &.not-started {
    color: ${colorsV2.black80};
    background-color: ${colorsV2.gray10};
  }

  ${fromScreen(768)} {
    padding: 2px 6px;
    border-radius: 8px;
  }
`;

interface TagStatusProps {
  status: 'done' | 'in-progress' | 'not-started';
  text: string;
}

export const ResponsiveTagStatus = (props: TagStatusProps) => {
  const { status, text } = props;
  return (
    <Main className={cl('tag-status-dictation', status)}>
      <ResponsiveTypography.Paragraph
        variant={['semi-bold/12-20', 'semi-bold/14-20']}
        breakpoints={[0, 768]}
      >
        {text}
      </ResponsiveTypography.Paragraph>
    </Main>
  );
};
