import { TestStatusEnum } from '@doltech/utils/lib/constants';
import * as React from 'react';
import { TagStatus } from '../../landing/ui/TagStatus/TagStatus';

interface PracticeTestStatusLoaderProps {
  testResult: any;
  totalQuestions: number;
}

export const PracticeTestStatusLoader = (props: PracticeTestStatusLoaderProps) => {
  const { testResult, totalQuestions } = props;
  const { status, totalAnsweredQuestions, testResult: result } = testResult || {};
  if (!totalQuestions) return null;
  switch (status) {
    case TestStatusEnum.IN_PROGRESS:
      return (
        <TagStatus
          status="in-progress"
          text={`Đang làm ${totalAnsweredQuestions}/${totalQuestions}`}
        />
      );
    case TestStatusEnum.COMPLETED:
      return <TagStatus status="done" text={`Đúng ${result?.correct}/${totalQuestions}`} />;
    default:
      return totalQuestions ? (
        <TagStatus status="not-started" text={`${totalQuestions} câu`} />
      ) : null;
  }
};
