import { components } from '@doltech/pageManagement/dto';
import { SwiperContent } from '@doltech/ui/lib/shared/components/PageSectionStructure/Content/SwiperContent';
import { PageSectionStructureV2 } from '@doltech/ui/lib/shared/components/PageSectionStructure/PageSectionStructureV2';
import * as React from 'react';

import { DictationCard } from '../../dictation-landing/components/DictationCard';

interface HomeDictationProps {
  data: components['schemas']['PageDictationWithOverviewDTO'];
  seeMoreUrl?: string;
  pageSection?: components['schemas']['PageSectionDTO'];
}

export const HomeDictation = (props: HomeDictationProps) => {
  const { data, seeMoreUrl, pageSection } = props;

  return (
    <PageSectionStructureV2
      oldStyling
      title="Chép chính tả"
      pageSection={pageSection}
      seeMoreUrl={seeMoreUrl}
      content={
        <SwiperContent
          data={data?.content}
          renderCard={(cardData) => <DictationCard data={cardData} />}
          breakpoints={[0, 1024, 1025]}
          slideWidths={[232, '100%', '100%']}
          gridColumns={['max-content', '1fr', '1fr']}
        />
      }
    />
  );
};
