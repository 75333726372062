import { routingUrls } from '@doltech/core/lib/routing/routing.config';
import { IconOtherTime } from '@doltech/icons/IconOtherTime';
import { IconSystemV2CourseTestVocabulary } from '@doltech/icons/SystemV2/IconSystemV2CourseTestVocabulary';
import { IconSystemV2InterfaceEssentialAnswerCheck2 } from '@doltech/icons/SystemV2/IconSystemV2InterfaceEssentialAnswerCheck2';
import { IconSystemV2SpecificBulb2 } from '@doltech/icons/SystemV2/IconSystemV2SpecificBulb2';

import {
  StructuralCard,
  StructuralItemLayout,
} from '@doltech/ui/lib/figma/Card/DolCard/TextOnlyCard/StructuralCard';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { useFilterStore } from '@doltech/ui/lib/figma/LandingFilterV2/useStore';
import no_image from '@doltech/ui/lib/images/No_image_available.png';
import { ItemDetailDoTestButton } from '@doltech/ui/lib/shared/components/ItemDetailDoTestButton/ItemDetailDoTestButton';
import {
  ContentGroupEnum,
  DATE_FORMAT,
  PageTypeEnum,
  TestTypeEnum,
} from '@doltech/utils/lib/constants';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import { getFullUrl, getLandingUrlFromPath } from '@doltech/utils/lib/url';
import cl from 'classnames';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Image from 'next/image';
import numeral from 'numeral';
import * as React from 'react';
import { useQuery } from 'react-query';

import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { Box } from '@doltech/ui/lib/figma/Common/BoxV2';
import { Link } from '@doltech/ui/lib/figma/Link';
import styled from 'styled-components';
import { fetchTestActions } from '../../page-management/landing/books/api/fetchTestActions';
import { PracticeTestCardActionUrlModel } from './PracticeTestCard';
import { PracticeTestStatusLoader } from './PracticeTestStatusLoader';

interface PracticeTestCardForHomePageProps {
  data: any;
  className?: string;
}

const ActionsType = [
  PageTypeEnum.VIEW_VOCAB.toString(),
  PageTypeEnum.VIEW_SOLUTION.toString(),
  PageTypeEnum.VIEW_TEST_QUESTION.toString(),
];

const TestStatusWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
`;

export const PracticeTestCardForHomePage = observer((props: PracticeTestCardForHomePageProps) => {
  const { className, data } = props;
  const { id, takes, snippet, passage, questionGroup, transcript, name, lastModifiedAt } = data;
  const { isLoggedIn } = useGetAuthInfo();

  const filterStore = useFilterStore();

  const description = passage || questionGroup || transcript;
  const snippetDescription = snippet?.passage || snippet?.questionGroup || snippet?.transcript;

  const testOverviewQuery = useQuery(
    [id, isLoggedIn],
    async () => {
      return fetchTestActions(TestTypeEnum.PRACTICE_TEST, id);
    },
    {
      retry: 1,
      retryDelay: 500,
      refetchOnMount: true,
    }
  );

  const pageUrl = React.useMemo(() => {
    const pages = testOverviewQuery?.data?.overview?.pages || [];

    const testQuestion = pages.find(
      ({ templateTypeId, url }) => url && templateTypeId === PageTypeEnum.VIEW_TEST_QUESTION
    );
    return getLandingUrlFromPath(testQuestion?.url);
  }, [testOverviewQuery?.data?.overview?.pages]);

  const imageProps = React.useMemo(() => {
    const result: any = {
      height: 'auto',
      width: '100%',
      position: 'relative',
      ratio: 3 / 2,
      isLoading: testOverviewQuery.isLoading,

      url: pageUrl,
      imageUrl: testOverviewQuery.data?.overview?.coverImage?.url,
      alt: testOverviewQuery.data?.overview?.coverImage?.alt || name,
    };
    return result;
  }, [
    pageUrl,
    testOverviewQuery.data?.overview?.coverImage?.alt,
    testOverviewQuery.data?.overview?.coverImage?.url,
    testOverviewQuery.isLoading,
    name,
  ]);

  const metaOrDescription = React.useMemo(() => {
    if (snippetDescription) {
      return {
        type: 'DESCRIPTION',
        defaultProps: {
          children: description,
          snippet: snippetDescription,
        },
      };
    }
    const sortGroup = (filterStore?.groups || []).find((g) => g.queryKey === 'sort');
    const metaTakes = {
      id: 'NUMBER_OF_TEST_TAKEN',
      text: `${numeral(takes || testOverviewQuery?.data?.overview?.numOfTestTakers || 0).format(
        '0, 0a'
      )} lượt làm`,
    };

    const metaPublishAt = sortGroup?.selectedValue?.includes('last_modified_at')
      ? {
          id: 'ELASTIC_PUBLISHED_AT',
          text: `Publish ngày ${moment(lastModifiedAt).format(DATE_FORMAT)}`,
        }
      : undefined;

    return {
      type: 'META',
      defaultProps: {
        metadata: [[metaPublishAt, metaTakes].find(Boolean)],
        color: 'gray100',
      },
    };
  }, [
    description,
    filterStore?.groups,
    lastModifiedAt,
    snippetDescription,
    takes,
    testOverviewQuery.data?.overview?.numOfTestTakers,
  ]);

  const testAction = React.useMemo(() => {
    const pages = testOverviewQuery?.data?.overview?.pages || [];
    const doTest = pages.find(
      ({ templateTypeId, url }) => url && templateTypeId === PageTypeEnum.DO_TEST
    );
    const actionsUrl: PracticeTestCardActionUrlModel[] = pages
      .filter(({ templateTypeId, url }) => url && ActionsType.includes(templateTypeId))
      .sort(
        (a, b) => ActionsType.indexOf(a?.templateTypeId) - ActionsType.indexOf(b?.templateTypeId)
      )
      .map((page) => {
        const { templateTypeId, url } = page;
        let icon;
        let text;
        switch (templateTypeId) {
          case PageTypeEnum.VIEW_TEST_QUESTION: {
            icon = <IconSystemV2InterfaceEssentialAnswerCheck2 color="black60" size={20} />;
            text = 'Đề và đáp án';
            break;
          }
          case PageTypeEnum.VIEW_SOLUTION: {
            icon = <IconSystemV2SpecificBulb2 color="black60" size={20} />;
            text = 'Giải thích';
            break;
          }
          case PageTypeEnum.VIEW_VOCAB:
          default: {
            icon = <IconSystemV2CourseTestVocabulary color="black60" size={20} />;
            text = 'Từ vựng';
            break;
          }
        }

        return {
          key: templateTypeId,
          url: getLandingUrlFromPath(url),
          icon,
          text,
        };
      });
    const altActions = [
      ...actionsUrl,
      testOverviewQuery?.data?.overview?.hasHistory
        ? {
            key: 'view_history',
            url:
              testOverviewQuery?.data?.overview?.testType === TestTypeEnum.FULL_TEST
                ? routingUrls.landing.STUDENT_TEST_HISTORY_FULL_PATH(
                    testOverviewQuery?.data?.overview?.id
                  )
                : routingUrls.landing.STUDENT_TEST_HISTORY_PRACTICE_PATH(
                    testOverviewQuery?.data?.overview?.id
                  ),
            icon: <IconOtherTime fontWeight={600} color={colorsV2.purple100} />,
            text: 'Lịch sử làm bài',
          }
        : null,
    ].filter(Boolean);

    return {
      type: 'CUSTOM',
      isLoading: testOverviewQuery.isLoading,
      render: () => {
        return (
          <ItemDetailDoTestButton
            item={{
              type: ContentGroupEnum.PASSAGE,
              status: testOverviewQuery?.data?.overview?.testResult?.status,
              url: doTest?.url,
            }}
            altActions={altActions}
          />
        );
      },
    };
  }, [
    testOverviewQuery?.data?.overview?.hasHistory,
    testOverviewQuery?.data?.overview?.id,
    testOverviewQuery?.data?.overview?.pages,
    testOverviewQuery?.data?.overview?.testResult?.status,
    testOverviewQuery?.data?.overview?.testType,
    testOverviewQuery.isLoading,
  ]);

  const structuralCardItems: StructuralItemLayout[] = React.useMemo(() => {
    const cardLayout: any = {
      direction: 'vertical',
      gridTemplate: {
        areas: `
        "image"
        "card-content"`,
        rows: 'max-content 1fr',
        gap: [12, 12],
      },
    };
    return [
      {
        dataIndex: 'card-main',
        ...cardLayout,
        items: [
          {
            dataIndex: 'image',
            type: 'IMAGE',
            defaultProps: imageProps,
            render: () => {
              const { url, alt, imageUrl, ratio, ...rest } = imageProps;
              return (
                <Link prefetch={false} href={getFullUrl(imageProps?.url)} className="image-link">
                  <Box className="image-wrapper" {...rest}>
                    <Image
                      width={1}
                      height={1 / imageProps?.ratio}
                      alt={imageProps?.alt}
                      src={imageProps?.imageUrl || no_image}
                      quality={30}
                      objectFit="cover"
                      layout="responsive"
                      loading="lazy"
                      loader={imgProxyLoader}
                    />
                    <TestStatusWrapper>
                      <PracticeTestStatusLoader
                        totalQuestions={testOverviewQuery.data?.overview?.totalQuestions}
                        testResult={testOverviewQuery.data?.overview?.testResult}
                      />
                    </TestStatusWrapper>
                  </Box>
                </Link>
              );
            },
          },
          {
            dataIndex: 'card-content',
            direction: 'vertical',
            gridTemplate: {
              gap: [12, 12],
              rows: '1fr max-content',
              areas: `
              "card-info"
              "card-action"
              `,
            },
            items: [
              {
                dataIndex: 'card-info',
                direction: 'vertical',
                gridTemplate: {
                  gap: [4, 4],
                  rows: 'max-content max-content',
                  areas: `
                 "title"
                 "meta-or-description"
                 `,
                },
                items: [
                  {
                    dataIndex: 'title',
                    type: 'TITLE',
                    defaultProps: {
                      children: name,
                      url: pageUrl,
                      snippet: snippet?.name,
                    },
                  },
                  {
                    dataIndex: 'meta-or-description',
                    ...metaOrDescription,
                  },
                ],
              },
              {
                dataIndex: 'card-action',
                ...testAction,
              },
            ],
          },
        ],
      },
    ];
  }, [
    imageProps,
    metaOrDescription,
    name,
    pageUrl,
    snippet?.name,
    testAction,
    testOverviewQuery.data?.overview?.testResult,
    testOverviewQuery.data?.overview?.totalQuestions,
  ]);
  return (
    <StructuralCard
      className={cl('practice-test-v2-card', className)}
      items={structuralCardItems}
    />
  );
});
