import moment from 'moment';

import { DATE_FORMAT, MONTH_NAME_FORMAT, QUARTER_YEAR_FORMAT } from './constants';
import { leftPad } from './number';
import { capitalize, toUpper } from 'lodash';

export const isToday = (date: moment.MomentInput) => {
  return moment(date).isSame(Date.now(), 'day');
};

export const isSameDay = (date: moment.MomentInput, compareDate: moment.MomentInput) => {
  return moment(date).isSame(compareDate, 'day');
};

/**
 * expect to be in millis
 *
 * >= 1 day => display dd
 * < 1 day => display hh:mm
 * < 1h => display mm:ss
 * @param fromDate
 * @param toDate
 */
export const formatDiffDate = (fromDate: number, toDate = Date.now()) => {
  const oneDayInMillis = 1 * 24 * 60 * 60 * 1000;
  const oneHourInMillis = 1 * 60 * 60 * 1000;
  const diff = toDate - fromDate;

  const momentFromDiff = moment.duration(diff);
  if (diff >= oneDayInMillis) {
    // display day
    return `${momentFromDiff.days()} ngày`;
  } else if (oneHourInMillis < diff && diff < oneDayInMillis) {
    // display hh:mm
    return `${momentFromDiff.hours()} giờ ${momentFromDiff.minutes()} phút`;
  }

  // display mm:ss
  return `${momentFromDiff.minutes()} phút ${momentFromDiff.seconds()} giây`;
};

export const formatTime = (val: number): string => (val < 10 ? `0${val}` : `${val}`);

export const formatDate = (date: string, format: string) => {
  if (date) {
    return moment(date).locale('en').format(format);
  }
  return 'N/A';
};

export const formatDateVI = (date: string, format: string) => {
  if (date) {
    return moment(date).locale('vi').format(format);
  }
  return 'N/A';
};

export const formatStartDate = (date: string, format: string) => {
  if (date) {
    return moment(date).locale('en').startOf('day').format(format);
  }
  return 'N/A';
};

export const formatEndDate = (date: string, format: string) => {
  if (date) {
    return moment(date).locale('en').endOf('day').format(format);
  }
  return 'N/A';
};

export const formatStartDate2 = (date: string, format: string) => {
  if (date) {
    return moment(date).locale('en').utc().add(1, 'days').startOf('day').format(format);
  }
  return undefined;
};

export const formatEndDate2 = (date: string, format: string) => {
  if (date) {
    return moment(date).locale('en').utc().add(1, 'days').endOf('day').format(format);
  }
  return undefined;
};

export const formatStartDateWithParse = (date: string, parseFormat, format: string) => {
  if (date) {
    return moment(date, parseFormat).locale('en').startOf('day').format(format);
  }
  return 'N/A';
};

export const formatEndDateWithParse = (date: string, parseFormat, format: string) => {
  if (date) {
    return moment(date, parseFormat).locale('en').endOf('day').format(format);
  }
  return 'N/A';
};

export const formatDateWithParse = (date: string, parseFormat, format: string) => {
  if (date) {
    return moment(date, parseFormat).locale('en').format(format);
  }
  return 'N/A';
};

export const fixSafariDate = (dateStr: string) => {
  return moment(dateStr, 'YYYY-MM-DD HH:mm').toDate();
};

export const formatDateString = (date: string | Date, locale: 'en' | 'vi' = 'en') => {
  if (date) {
    return moment(date).locale(locale).format(MONTH_NAME_FORMAT);
  }
  return 'N/A';
};

export const formatDateStringForSEO = (date: string | Date, locale: 'en' | 'vi' = 'en') => {
  if (date) {
    return moment(date).format(DATE_FORMAT);
  }
  return 'N/A';
};

export const formatSecondToMoment = (seconds: number) => {
  const durationDate = new Date(0, 0, 0, 0, 0, 0, 0);
  durationDate.setSeconds(Math.floor(seconds));
  return moment(durationDate);
};

export const formatSecondToMMss = (seconds: number) => {
  return formatSecondToMoment(seconds).format('mm:ss');
};

export const formatSecondToHHmm = (seconds: number) => {
  return formatSecondToMoment(seconds).format('HH:mm');
};

export const formatSecondToHHMMss = (seconds: number) => {
  return formatSecondToMoment(seconds).format('HH:mm:ss');
};

export const formatSecondToMss = (seconds: number) => {
  return formatSecondToMoment(seconds).format('m:ss');
};

export const formatSecondToMinutes = (seconds: number) => Math.ceil(seconds / 60);

export const formatMomentToSeconds = (input: moment.Moment): number =>
  Math.max(input.minutes() * 60 + input.seconds(), 0);

export const formatMomentToMMss = (input: moment.Moment): string => input.format('mm:ss');

export const formatMMssToMoment = (input: string): moment.Moment => moment(input, 'mm:ss');

export const formatMMssToSeconds = (input: string): number =>
  formatMomentToSeconds(formatMMssToMoment(input));

export const formatMomentToHHmm = (input: moment.Moment): string => input.format('HH:mm');

// Convert seconds to number of days, hours, minutes, seconds.
// Ex: 15000 seconds to "4 hours 10 minutes".
//     600000 seconds to "6 days 22 hours"
export const formatSecondsToNoOfDhms = (
  seconds: number,
  acronym = false,
  acronymSymbols = ['d', 'h', 'm', 's']
) => {
  const timeUnits = [
    { unit: 'day', acronym: acronymSymbols[0], duration: 3600 * 24 },
    { unit: 'hour', acronym: acronymSymbols[1], duration: 3600 },
    { unit: 'minute', acronym: acronymSymbols[2], duration: 60 },
    { unit: 'second', acronym: acronymSymbols[3], duration: 1 },
  ];

  let useSeconds = seconds;

  let displayDuration = '';
  // eslint-disable-next-line @typescript-eslint/no-shadow
  for (const timeUnit of timeUnits) {
    const value = Math.floor(useSeconds / timeUnit.duration);
    useSeconds %= timeUnit.duration;

    if (value > 0) {
      const unitDisplay = acronym
        ? `${value}${timeUnit.acronym}`
        : `${value} ${timeUnit.unit}${value === 1 ? '' : 's'}`;
      displayDuration += displayDuration ? ' ' : '';
      displayDuration += unitDisplay;
    }
  }

  return displayDuration || '-';
};

export const secondsToShortTimeString = (seconds: number) => {
  const roundSeconds = Math.round(seconds);

  const minutes = Math.floor(roundSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(weeks / 4);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return `${years}y ${months % 12 > 0 ? `${months % 12}mo` : ''}`;
  }
  if (months > 0) {
    return `${months}mo ${weeks % 4 > 0 ? `${weeks % 4}w` : ''}`;
  }
  if (weeks > 0) {
    return `${weeks}w ${days % 7 > 0 ? `${days % 7}d` : ''}`;
  }
  if (days > 0) {
    return `${days}d ${hours % 24 > 0 ? `${hours % 24}h` : ''}`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes % 60 > 0 ? `${minutes % 60}m` : ''}`;
  }
  if (minutes > 0) {
    return `${minutes}m ${roundSeconds % 60}s`;
  }
  return `${roundSeconds}s`;
};

export const secondsToShortTimeStringTillSecond = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ${hours % 24}h`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  }
  return `${seconds}s`;
};

export const secondsToMinuteFormat = (seconds: number) => Math.ceil(seconds / 60);
export const secondsToHourFormat = (seconds: number) => Math.round(seconds / 3600);
export const secondsToDaysFormat = (seconds: number) => Math.round(seconds / (3600 * 24));

export const secondsToHm = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = h > 0 ? `${h}h` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  if (hDisplay === '' && mDisplay === '') {
    return '0h';
  }
  return hDisplay + mDisplay;
};

export const secondsToHms = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = h > 0 ? `${h}h` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  if (hDisplay === '' && mDisplay === '') {
    return `${seconds}s`;
  }
  return hDisplay + mDisplay;
};

export const secondsToHourMinutes = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = h > 0 ? `${h} giờ` : '';
  const mDisplay = m > 0 ? `${m} phút` : '';
  if (hDisplay === '' && mDisplay === '') {
    return '0 giờ';
  }
  return [hDisplay, mDisplay].join(' ').trim();
};

export const secondsToMinuteSeconds = (seconds: number) => {
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);

  const mDisplay = m > 0 ? `${m} phút` : '';
  const sDisplay = s > 0 ? `${s} giây` : '';
  if (mDisplay === '' && sDisplay === '') {
    return '0 phút';
  }
  return [mDisplay, sDisplay].join(' ').trim();
};

export const convertSecondsToMinutedAndSecondsObject = (seconds: number) => {
  if (isNaN(seconds)) {
    return { min: '00', sec: '00' };
  }

  const calculatedMinutes = Math.floor(seconds / 60);
  const calculatedSeconds = seconds % 60;

  const min = calculatedMinutes > 9 ? calculatedMinutes.toString() : `0${calculatedMinutes}`;
  const sec = calculatedSeconds > 9 ? calculatedSeconds.toString() : `0${calculatedSeconds}`;
  return { min, sec };
};

export const convertMillisecondsToMinuteAndSecondObject = (milliseconds: number) => {
  const timeInSeconds = Math.floor(milliseconds / 1000);
  const { min, sec } = convertSecondsToMinutedAndSecondsObject(timeInSeconds);
  return { min, sec };
};

export const convertSecondsToMinutedAndSeconds = (seconds: number) => {
  const { min, sec } = convertSecondsToMinutedAndSecondsObject(seconds);
  return `${min}:${sec}`;
};

export const getQuarterDefaultValue = ({ quarter, year }) => {
  if (!quarter || !year) {
    return undefined;
  }
  return moment(`${year}-${quarter}`, QUARTER_YEAR_FORMAT);
};

export const getDatePickerDefaultValue = ({ from, to }) => {
  if (!from && !to) {
    return undefined;
  }

  return [from, to];
};

export const getMonthDateRange = (year, month) => {
  // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
  // array is 'year', 'month', 'day', etc
  const startDate = moment([year, month - 1]);

  // Clone the value before .endOf()
  const endDate = moment(startDate).endOf('month');

  // make sure to call toDate() for plain JavaScript date type
  return { start: startDate, end: endDate };
};

export const getMonths = (short = false, locale = 'en') => {
  if (short) {
    return moment.localeData(locale).monthsShort();
  }

  return moment.localeData(locale).months();
};
export const getStartWeekByMonth = (date) => {
  const startOfMonth = moment(date).startOf('month');
  const isStartMonth = startOfMonth.month() === 0; // January
  let startWeek = startOfMonth.isoWeek(); // Use ISO week numbering

  if (isStartMonth && startWeek > 1) {
    startWeek = 0;
  }

  return {
    startWeek,
    originalStartWeek: startWeek,
  };
};

export const getEndWeekByMonth = (date) => {
  const endOfMonth = moment(date).endOf('month');
  const isEndMonth = endOfMonth.month() === 11; // December
  let endWeek = endOfMonth.isoWeek(); // Use ISO week numbering

  if (isEndMonth && endWeek === 1) {
    // If it's the end of December and week 1, consider it part of the previous year
    endWeek = moment(date).subtract(1, 'week').endOf('month').isoWeek();
  }

  return {
    endWeek,
    originalEndWeek: endWeek,
  };
};

export const getCalendarByMonth = (date, locale = 'vi') => {
  const numberOfWeekDay = 7;
  const { startWeek } = getStartWeekByMonth(date);
  const { endWeek } = getEndWeekByMonth(date);
  const calendar = [];

  for (let week = startWeek; week <= endWeek; week++) {
    const days = Array(numberOfWeekDay)
      .fill(0)
      .map((_, index) => {
        const startWeekday = moment(date)
          .isoWeek(week)
          .locale(locale)
          .startOf('isoWeek')
          .isoWeekday(1)
          .clone();
        const newDate = startWeekday.add(index, 'day');

        let inactive = false;

        if (week === startWeek) {
          inactive = newDate.date() > 10;
        }

        if (week === endWeek) {
          inactive = newDate.date() < 10;
        }

        return {
          date: newDate.toString(),
          dateNumber: newDate.format('DD'),
          inactive,
          isToday: isToday(newDate),
        };
      });

    calendar.push({
      week,
      days,
    });
  }

  return calendar;
};

export const convertIntToTime = (input) => {
  if (!input) {
    return undefined;
  }
  const date = moment().startOf('day').add(input, 'minutes');
  return date.format('HH:mm');
};

export const convertSecondsToHourMinutedAndSeconds = (seconds: number, endCharacters = 's') => {
  if (isNaN(seconds)) {
    return '00:00s';
  }
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  if (h > 0) {
    return `${leftPad(h)}:${leftPad(m)}:${leftPad(s)}${endCharacters}`;
  }
  return `${leftPad(m)}:${leftPad(s)}${endCharacters}`;
};

export const convertSecondsToHourAndMin = (seconds: number) => {
  if (isNaN(seconds)) {
    return '-';
  }
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  if (h > 0) {
    return `${h}h${m > 0 ? `${m}m` : ''}`;
  }
  if (m < 1) {
    return '-';
  }
  return `${m}m`;
};

export const customFromNowByDay = (value, locale = 'en') => {
  const mValue = moment.isMoment(value) ? value.locale(locale) : moment(value).locale(locale);
  const dayDiff = moment().diff(mValue, 'days');
  if (dayDiff > 1) {
    return mValue.format(DATE_FORMAT);
  }
  return `Today ${mValue.format('hh:mm A')}`;
};

export const dateRangeToMoments = (
  dateRange: [string, string] | string[]
): [moment.Moment, moment.Moment] =>
  dateRange.map((eachDate) => {
    if (eachDate) {
      return moment(eachDate);
    }
    return null;
  }) as [moment.Moment, moment.Moment];

export const convertDaysToVietnamese = (days) => {
  if (days) {
    const dayRetrieves = days.split(',').map((day) => day.trim().toLowerCase());
    const dayMap = {
      mon: '2',
      tue: '3',
      wed: '4',
      thu: '5',
      fri: '6',
      sat: '7',
      sun: 'CN',
    };

    const convertedDays = dayRetrieves.map((day) => dayMap[day]);

    return `Thứ ${convertedDays.join(' - ')}`;
  }
  return 'Invalid days';
};

export const makeClassDateMoreShorter = (days) => {
  if (days) {
    const dayRetrieves = days.split(',').map((day) => day.trim().toLowerCase());
    return dayRetrieves.map((day) => capitalize(day.substring(0, 2))).join(', ');
  }
  return 'Invalid days';
};

// 19:00 -> 19h00, 09:00 -> 09h00, 9:00 -> 09h00
export const HHmmToHH_h_mm = (time) => {
  if (time) {
    const [hour, minute] = time.split(':');
    return `${leftPad(hour)}h${leftPad(minute)}`;
  }
  return time;
};

// "Sat , Sun" -> ['Sat' , 'Sun']
export const classDateToDaysOfWeek = (classDate) => {
  return (classDate || '')
    .split(',')
    .map((day) => day.trim())
    .filter(Boolean)
    .map(toUpper);
};

export const getLastActiveFormat = (date) => {
  const now = moment();
  const diffInSeconds = now.diff(moment(date), 'seconds');
  if (diffInSeconds < 60) {
    return 'Online';
  }
  if (diffInSeconds < 60 * 60) {
    return `${Math.floor(diffInSeconds / 60)} phút trước`;
  }
  if (diffInSeconds < 60 * 60 * 24) {
    return `${Math.floor(diffInSeconds / 3600)} giờ trước`;
  }
  if (diffInSeconds < 60 * 60 * 24 * 30) {
    return `${Math.floor(diffInSeconds / (3600 * 24))} ngày trước`;
  }

  return moment(date).format(DATE_FORMAT);
};

export const convertDateFormatToAnother = (date: string, currentFm: string, newFormat: string) => {
  const dateMoment = moment(date, currentFm);
  if (dateMoment.isValid()) {
    return dateMoment.format(newFormat);
  }
  return date;
};
