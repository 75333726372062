import { apis } from '@doltech/core/lib/api/api.config';
import { components } from '@doltech/pageManagement/dto';
import { TestTypeEnum } from '@doltech/utils/lib/constants';

import { fetchPublicPracticeTest } from '../../../../practice-test/api/practice-test';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export const fetchTestActions = async (testType: TestTypeEnum, testId: string) => {
  const { data } = await apis.publicPageManagement.get<components['schemas']['TestDetailDTO']>(
    apiPublicUrls.pageManagement.GET_TEST_INFORMATION_DETAIL,
    {
      testType,
      testId,
    }
  );

  const result = data as any;

  if (data?.pages?.length) {
    const doTestIndex = data.pages.findIndex((page) => page.templateTypeId === 'DO_TEST');
    if (doTestIndex >= 0) {
      const res = await fetchPublicPracticeTest({ ids: [testId] });
      if (res?.content?.length) {
        result.overview = res?.content?.[0];
      }
    }
  }

  return result;
};
