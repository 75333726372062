import { useLandingSharedDataStore } from '@doltech/ui/lib/figma/Layout/Landing/model/landing-shared-data.hook';
import { SwiperContent } from '@doltech/ui/lib/shared/components/PageSectionStructure/Content/SwiperContent';
import { PageSectionStructureV2 } from '@doltech/ui/lib/shared/components/PageSectionStructure/PageSectionStructureV2';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { getLandingUrlFromPath } from '@doltech/utils/lib/url';
import * as React from 'react';

import { BookOverviewCard } from './BookOverviewCard';

export const IEOnlineTestComponent = ({ data, className, pageSection, imgPriority = false }) => {
  const landingStore = useLandingSharedDataStore();
  return (
    <PageSectionStructureV2
      className={className}
      oldStyling
      title="IELTS Online Test"
      pageSection={pageSection}
      seeMoreUrl={getLandingUrlFromPath(
        landingStore?.pageLink?.staticPageLink?.[PageTypeEnum.LANDING_FULL_TEST]?.url || '#'
      )}
      content={
        <SwiperContent
          prefix="ielts_online_test"
          data={data}
          breakpoints={[0, 768, 769]}
          slideWidths={[232, '100%', '100%']}
          gridColumns={['max-content', '1fr', '1fr']}
          renderCard={(cardData, cardDataIndex) => (
            <BookOverviewCard
              imgPriority={cardDataIndex === 0 ? imgPriority : false}
              key={cardData.id}
              data={cardData}
            />
          )}
        />
      }
    />
  );
};
