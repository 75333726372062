import {
  StructuralCard,
  StructuralItemLayout,
} from '@doltech/ui/lib/figma/Card/DolCard/TextOnlyCard/StructuralCard';
import { TextOnlyCardProps } from '@doltech/ui/lib/figma/Card/DolCard/TextOnlyCard/TextOnlyCard';
import numeral from 'numeral';
import * as React from 'react';

import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { MONTH_DAY_NAME_FORMAT } from '@doltech/utils/lib/constants';
import { formatDate } from '@doltech/utils/lib/date';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import { getFullUrl } from '@doltech/utils/lib/url';
import Image from 'next/image';

import { Box } from '@doltech/ui/lib/figma/Common/BoxV2';
import { Link } from '@doltech/ui/lib/figma/Link';
import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import styled from 'styled-components';

interface HomeNewsCardProps extends TextOnlyCardProps {
  data: any;
  order: number;
}

const NewsImageWrapper = styled.div`
  position: relative;
  .order {
    position: absolute;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
    right: -20px;
    bottom: -24px;
  }
`;

export const HomeNewsCard = (props: HomeNewsCardProps) => {
  const { data, order } = props;
  const { page } = data;
  const { title, createdAt } = data;
  const [, from768] = useFromScreens([0, 768]);

  const url = page?.url || '#';
  const imageUrl = page?.dol?.fileInfo?.url || '';

  const structuralCardItems: StructuralItemLayout[] = React.useMemo(() => {
    return [
      {
        dataIndex: 'card-main',
        direction: 'horizontal',
        gridTemplate: {
          areas: `"image card-content"`,
          columns: 'max-content 1fr',
          gap: [24, 24],
        },
        items: [
          {
            dataIndex: 'image',
            render: () => (
              <Link prefetch={false} href={getFullUrl(url)} className="image-link">
                <NewsImageWrapper>
                  <Box className="image-wrapper" width={from768 ? 56 : 52}>
                    <Image
                      width={1}
                      height={1}
                      alt="news"
                      src={imageUrl}
                      quality={30}
                      objectFit="cover"
                      layout="responsive"
                      loading="lazy"
                      loader={imgProxyLoader}
                    />
                  </Box>

                  <ResponsiveTypography.Paragraph
                    breakpoints={[0, 768]}
                    variant={['bold/32-40', 'bold/40-48']}
                    color="black20"
                    className="order"
                  >
                    {numeral(order + 1).format('00')}
                  </ResponsiveTypography.Paragraph>
                </NewsImageWrapper>
              </Link>
            ),
          },
          {
            dataIndex: 'card-content',
            direction: 'vertical',
            gridTemplate: {
              gap: [4, 4],
              rows: 'max-content max-content',
              areas: `
                "meta"
                "title"
              `,
            },
            items: [
              {
                dataIndex: 'meta',
                type: 'META',
                defaultProps: {
                  metadata: [
                    {
                      id: 'date',
                      text: formatDate(createdAt, MONTH_DAY_NAME_FORMAT),
                    },
                    {
                      id: 'author',
                      text: 'DOL IELTS Đình Lực',
                    },
                  ],
                },
              },
              {
                dataIndex: 'title',
                type: 'TITLE',
                defaultProps: {
                  children: title,
                  url,
                },
              },
            ],
          },
        ],
      },
    ];
  }, [createdAt, imageUrl, title, url, from768, order]);
  return <StructuralCard className="home-news-card" items={structuralCardItems} />;
};
