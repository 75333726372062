import {
  StructuralCard,
  StructuralItemLayout,
} from '@doltech/ui/lib/figma/Card/DolCard/TextOnlyCard/StructuralCard';
import { TextOnlyCardProps } from '@doltech/ui/lib/figma/Card/DolCard/TextOnlyCard/TextOnlyCard';
import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { getLandingUrlFromPath } from '@doltech/utils/lib/url';
import * as React from 'react';

export interface LandingSampleCardProps extends TextOnlyCardProps {
  data: any;
}

export const LandingSampleCard = (props: LandingSampleCardProps) => {
  const { data, description = data?.description } = props;
  const { snippet, title, thumbnail, page, url } = data;
  const [, from1128] = useFromScreens([0, 1128]);
  const pageUrl = getLandingUrlFromPath(page?.url || url, 'landing');
  const imageProps = React.useMemo(() => {
    if (from1128) {
      return {
        ratio: 3 / 2,
        width: 252,
      };
    }
    return {
      ratio: 3 / 2,
      width: 232,
    };
  }, [from1128]);

  const structuralCardItems: StructuralItemLayout[] = React.useMemo(() => {
    return [
      {
        dataIndex: 'card-main',
        direction: 'vertical',
        gridTemplate: {
          areas: `
          "image" 
          "card-content"
          `,
          gap: [12, 12],
        },
        items: [
          {
            dataIndex: 'image',
            type: 'IMAGE',
            defaultProps: {
              url: pageUrl,
              imageUrl: thumbnail.url,
              alt: thumbnail.alt || title,
              ...imageProps,
            },
          },
          {
            dataIndex: 'card-content',
            direction: 'vertical',
            gridTemplate: {
              gap: [4, 4],
              areas: `
                "title"
                "description"
                `,
            },
            items: [
              {
                dataIndex: 'title',
                type: 'TITLE',
                defaultProps: {
                  children: title,
                  url: pageUrl,
                  snippet: snippet?.title,
                },
              },

              {
                dataIndex: 'description',
                type: 'DESCRIPTION_LISTING_HORIZONTAL',
                defaultProps: {
                  children: description,
                  snippet: snippet?.description,
                },
              },
            ],
          },
        ],
      },
    ];
  }, [imageProps, description, pageUrl, snippet, thumbnail.alt, thumbnail.url, title]);
  return (
    <StructuralCard
      overrideDescriptionEllipsis={{ rows: 2 }}
      className="landing-writing-sample-card"
      items={structuralCardItems}
    />
  );
};
